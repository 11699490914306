/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import manropeBold from '../../../../fonts/manrope/Manrope-Bold.woff';
import manropeExtraBold from '../../../../fonts/manrope/Manrope-ExtraBold.woff';
import manrope from '../../../../fonts/manrope/Manrope-Regular.woff';
import manropeSemiBold from '../../../../fonts/manrope/Manrope-SemiBold.woff';
import defaultImage from '../../../../images/broken-img.webp';
import { capitalize } from '../../../../utils/strings';
import pdfStyle from './pdfStyle';

// Create styles
const styles = StyleSheet.create(pdfStyle);

function DocumentPDF({ data }) {
    const { pharmacyInfos } = data;
    const { client, createdAt, images, list } = data.data;

    return (
        <Document>
            <Page size='A4' style={styles.body} wrap>
                <View style={styles.top}>
                    <View style={styles.section}>
                        <Text style={[styles.bold, styles.mrb]}>{pharmacyInfos.name}</Text>
                        <Text style={styles.mrb}>{pharmacyInfos.address}</Text>
                        <View style={[styles.textInline, styles.mrb]}>
                            <Text style={styles.marginRight}>{pharmacyInfos.zip_code}</Text>
                            <Text>{pharmacyInfos.city}</Text>
                        </View>
                        <View style={[styles.textInline, styles.mrb]}>
                            <Text style={styles.marginRight}>Tel: </Text>
                            <Text>{pharmacyInfos.phone}</Text>
                        </View>
                    </View>

                    <View>
                        <Text style={styles.mrb}>Liste. de Mr / Mme {capitalize(client.lastname)}</Text>
                        <View style={styles.textInline}>
                            <Text style={styles.marginRight}>Effectuée le</Text>
                            <Text style={styles.bold}>{createdAt}</Text>
                        </View>
                    </View>
                </View>

                <View>
                    <Text style={styles.center}>Ma liste de matériel médical</Text>
                </View>
                {list.map((item, i) => (
                    <View key={item.id} wrap={false}>
                        <View style={styles.product}>
                            <Image style={styles.image} src={images?.[i] ?? defaultImage} />
                            <View style={{ flex: 2, paddingRight: '20px' }}>
                                <Text style={styles.title}>{item.product.name}</Text>
                                <Text style={[styles.text8, styles.description]}>
                                    {ReactHtmlParser(item.product.description)}
                                </Text>
                                <View
                                    style={{
                                        flexWrap: 'wrap',
                                        flexDirection: 'row',
                                        width: '100%',
                                    }}
                                >
                                    <View style={styles.attr}>
                                        <Text style={[styles.semiBold, styles.marginRight]}>Quantité :</Text>
                                        <Text style={styles.text8}>{item.count}</Text>
                                    </View>

                                    <View style={styles.attr}>
                                        <Text style={[styles.semiBold, styles.marginRight]}>Location :</Text>
                                        <Text style={styles.text8}>{item.product.tag_loc ? 'Oui' : 'Non'}</Text>
                                    </View>

                                    <View style={styles.attr}>
                                        <Text style={[styles.semiBold, styles.marginRight]}>Couleur :</Text>
                                        <Text style={styles.text8}>{item.product.colors}</Text>
                                    </View>

                                    <View style={styles.attr}>
                                        <Text style={[styles.semiBold, styles.marginRight]}>Remboursement :</Text>
                                        <Text style={styles.text8}>{item.product.tag_lpp ? 'Oui' : 'Non'}</Text>
                                    </View>

                                    <View style={styles.attr}>
                                        <Text style={[styles.semiBold, styles.marginRight]}>Poids :</Text>
                                        <Text style={styles.text8}>{item.product.weight} kg</Text>
                                    </View>

                                    <View style={styles.attr}>
                                        <Text style={[styles.semiBold, styles.marginRight]}>Marque :</Text>
                                        <Text style={styles.text8}>{item.product.brand}</Text>
                                    </View>

                                    <View style={styles.attr}>
                                        <Text style={[styles.semiBold, styles.marginRight]}>Charge max :</Text>
                                        <Text style={styles.text8}>{item.product.maximum_charge} kg</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{ fontSize: 10, alignItems: 'flex-end' }}>
                                <Text style={{ fontSize: 10, color: '#2B5279' }}>
                                    {item.product.price !== 0 ? <>{item.product.price} € TTC</> : 'N/C'}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.border} />
                    </View>
                ))}
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    );
}

Font.register({
    family: 'Manrope',
    fonts: [
        { src: manrope },
        { src: manropeSemiBold, fontWeight: 600 },
        { src: manropeBold, fontWeight: 700 },
        { src: manropeExtraBold, fontWeight: 800 },
    ],
});

DocumentPDF.propTypes = {
    data: PropTypes.object.isRequired,
    // pharmacyInfos: PropTypes.object.isRequired,
};

export default DocumentPDF;
