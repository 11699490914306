import React, { useEffect, useState } from 'react';
import ClampLines from 'react-clamp-lines';

import './ProductItem.styl';
import { BROWSING_BASE_PATH } from '../../../../constants/router';
import { useProductDb } from '../../../../database/products/useProductDb';
import defaultImage from '../../../../images/broken-img.webp';
import ProductItemImages from '../../../components/ProductItemImages';
import ProductItemInfos from '../../../components/ProductItemInfos';
import ProductItemPagination from '../../../components/ProductItemPagination/index';
import ProductItemSimilarProducts from '../../../components/ProductItemSimilarProducts';
import SEO from '../../../components/SEO';
import ProductAddToCartButton from '../../../features/cart/AddToCartButton';
import TogglSwitchButton from '../../../features/productState/TogglSwitchButton/index';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';

// eslint-disable-next-line react/prop-types
function ProductItem({ productId, subcategoryId }) {
    const [productInfos, setProductInfos] = useState(null);
    const productDb = useProductDb();

    useEffect(() => {
        productDb
            .get(productId)
            .then((result) => {
                const category = result.sub_category.category;
                category.path = BROWSING_BASE_PATH + category.id;

                const subcategory = result.sub_category;
                subcategory.path = category.path + '/' + subcategory.id;

                const product = result;
                setProductInfos({ category, product, subcategory });
            })
            .catch((err) => {
                console.error('ProductItem -> err', err);
            });
    }, [productDb, productId]);

    if (productInfos === null) {
        return <div>Chargement</div>;
    }

    return (
        <BreadcrumbLayout historyBack crumbs={productInfos}>
            <SEO title='Medikiosk' />
            <div id='product-item-page'>
                <div className='product-item-card'>
                    <ProductItemPagination product={productInfos.product} />
                    <div className='card-content'>
                        <div className='left'>
                            <div className='ico-bloc'>
                                {productInfos.product.tag_lpp ? <div className='ico ico-lpp' /> : null}
                                {productInfos.product.tag_loc ? <div className='ico ico-loc' /> : null}
                            </div>
                            <ProductItemImages images={productInfos?.product?.images ?? [defaultImage]} />

                            {productInfos.product.cross_selling.length > 0 ? (
                                <ProductItemSimilarProducts
                                    subcategoryId={subcategoryId}
                                    product={productInfos.product}
                                />
                            ) : null}
                        </div>
                        <div className='right'>
                            <div className='title-bloc'>
                                <h2>
                                    <ClampLines
                                        id={`product-item-clamp-${productInfos.product.id}`}
                                        text={productInfos.product.name}
                                        buttons={false}
                                        lines={2}
                                    />
                                </h2>
                                <div className='price-label'>
                                    {productInfos.product.price !== 0 && productInfos.product.price !== '0.00' ? (
                                        <>
                                            {productInfos.product.price} €<span className='alt'> TTC</span>
                                        </>
                                    ) : (
                                        'N/C'
                                    )}
                                </div>
                            </div>
                            <div className='description-bloc'>
                                <div className='h3-like'>Description</div>
                                {productInfos.product.description !== null ? (
                                    <div
                                        className='txt-description'
                                        dangerouslySetInnerHTML={{
                                            __html: productInfos.product.description,
                                        }}
                                    />
                                ) : (
                                    <div className='txt-description'>Pas de déscription</div>
                                )}
                            </div>
                            <ProductAddToCartButton product={productInfos.product} />
                            <ProductItemInfos {...productInfos.product} />
                        </div>
                    </div>
                </div>

                <div className='product-item-toolbar'>
                    <div className='product-item-switch-container'>
                        <TogglSwitchButton type='product' id={productInfos.product.id} data={productInfos.product} />
                    </div>
                </div>
            </div>
        </BreadcrumbLayout>
    );
}

export default ProductItem;
