/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Pluralize from 'react-pluralize';

import { useProductDb } from '../../../../database/products/useProductDb';
import useProductSearchQueries from '../../../pouchDBQueries/useProductSearchQueries';
import { usePagination } from '../../pagination/PaginationContext';

function SearchResultCount({ searchTerm }) {
    const [products, setProducts] = useState(null);
    const productDb = useProductDb();
    const { setTotalCount } = usePagination();
    const { loading, queryError, results } = useProductSearchQueries({
        params: { fields: ['_id'] },
        searchTerm,
    });

    useEffect(() => {
        if (searchTerm && !loading && !queryError) {
            setProducts(results.length);
            setTotalCount(results.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, queryError, results, searchTerm, productDb]);

    return (
        <div className='h3-like product-count'>
            {products !== null ? <Pluralize singular='résultat' count={products} zero='0 résultat' /> : '...'}
        </div>
    );
}

SearchResultCount.propTypes = {
    searchTerm: PropTypes.string.isRequired,
};

export default SearchResultCount;
