import { navigate } from '@reach/router';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PAGE_DIAGNOSTIC_RESULTS } from '../../../../../constants/router';
import { diagnosticCategories } from '../../constants/categories';
import { nextStep, previousStep, selectResponse } from '../../reducer';

import './DiagnosticStep.styl';

function DiagnosticStep(props) {
    const dispatch = useDispatch();
    const categories = diagnosticCategories;

    const currentCategoryIndex = useSelector((state) => state.diagnostic.currentCategoryIndex);
    const currentSubcategoryIndex = useSelector((state) => state.diagnostic.currentSubcategoryIndex);
    const isCompleted = useSelector((state) => state.diagnostic.isCompleted);

    const [selected, setSelected] = useState({ answer: null, subcategoryId: null });

    const saveResponse = () => {
        if (selected !== null) {
            dispatch(
                selectResponse({
                    categoryId: categories[currentCategoryIndex].id,
                    responseId: selected.answer,
                    subcategoryId: selected.subcategoryId,
                }),
            );
            dispatch(nextStep());
        }
    };

    const currentSubcategory = categories[currentCategoryIndex].subCategories[currentSubcategoryIndex];
    const isButtonDisabled = selected.subcategoryId !== currentSubcategory.id || selected.answer === null;

    useEffect(() => {
        if (isCompleted) {
            navigate(PAGE_DIAGNOSTIC_RESULTS);
        }
    }, [isCompleted]);

    return (
        <div id='quizz-wrapper'>
            <div id='categories-wrapper'>
                {categories.map((category, index) => {
                    const isCompleted = index < currentCategoryIndex;
                    const isCurrent = index === currentCategoryIndex;

                    return (
                        <div
                            className={cn('category', { completed: isCompleted }, { current: isCurrent })}
                            key={category.label}
                        >
                            <div className='category-label'>{category.label}</div>
                            <div className='category-icon'>
                                <div className={`icon icon2-${category.icon}`} />
                            </div>
                        </div>
                    );
                })}
            </div>

            <div id='subcategories-wrapper'>
                <div className='category-img'>
                    <img src={categories[currentCategoryIndex].image} alt={categories[currentCategoryIndex].label} />
                </div>

                <div className='question-wrapper'>
                    <div className='question-label'>{currentSubcategory.questionLabel}</div>

                    <div className='answers-wrapper'>
                        {currentSubcategory.answers.map((answer) => {
                            return (
                                <button
                                    className={cn('answer-button', {
                                        selected:
                                            selected.answer === answer.answerId &&
                                            selected.subcategoryId === currentSubcategory.id,
                                    })}
                                    key={`${currentSubcategory.id}-${currentSubcategory.label}-${answer.answerId}}`}
                                    onClick={() =>
                                        setSelected({ answer: answer.answerId, subcategoryId: currentSubcategory.id })
                                    }
                                >
                                    <div className='answer-label'>{answer.label}</div>
                                </button>
                            );
                        })}
                    </div>
                </div>

                <div className='quizz-buttons-wrapper'>
                    {currentCategoryIndex === 0 && currentSubcategoryIndex === 0 ? null : (
                        <button className='quizz-button' onClick={() => dispatch(previousStep())}>
                            Précédent
                        </button>
                    )}
                    <button disabled={isButtonDisabled} className='quizz-button next' onClick={() => saveResponse()}>
                        Continuer
                    </button>
                </div>
            </div>
        </div>
    );
}

DiagnosticStep.propTypes = {};

export default DiagnosticStep;
