import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useProductDb } from '../../../database/products/useProductDb';
import { getProductPath } from '../../../utils/products';

function ProductLink({ children, id, ...props }) {
    const productDb = useProductDb();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        productDb
            .get(id)
            .then((result) => {
                setProduct(result);
            })
            .catch((err) => {
                console.error('ProductLink -> err', err);
            });
    }, [productDb, id]);

    if (product === null) {
        return null;
    }

    return (
        <Link to={getProductPath(product)} {...props}>
            {children}
        </Link>
    );
}

ProductLink.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
};

export default ProductLink;
