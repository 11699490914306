import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import './TogglSwitchButton.styl';
import { toggleItem } from '../reducer';
import { getProductActiveState } from '../utils';

function TogglSwitchButton({ id, isActive, isAdmin, toggleItem, type }) {
    function handleChange() {
        toggleItem({ id, type });
    }

    return isAdmin ? (
        <button className={cn('switch', { active: isActive })} onClick={handleChange}>
            <span className='slider' />
        </button>
    ) : null;
}

TogglSwitchButton.defaultProps = {
    data: null,
    isActive: true,
};

TogglSwitchButton.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    data: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isActive: PropTypes.bool,
    isAdmin: PropTypes.bool.isRequired,
    toggleItem: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const isActive = getProductActiveState(ownProps.type, ownProps.data, ownProps.id, state.productState);

    return {
        isActive,
        isAdmin: state.admin.loggedIn,
    };
};

const mapDispatch = { toggleItem };

export default connect(mapStateToProps, mapDispatch)(TogglSwitchButton);
