import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { usePagination } from './PaginationContext';
import './Pagination.styl';

function Pagination({ desc, style, subcategoryId }) {
    const { page, reset, setPage, totalCount } = usePagination();

    const selectorTypeEq = desc ? '$lte' : '$gte';
    const selectorType = desc ? '$lt' : '$gt';

    const prevResult = () => {
        setPage({
            ...page,
            currentPage: page.currentPage - 1,
            selector: {
                [selectorTypeEq]: page.prevFirstItem[page.currentPage - 1],
            },
        });
        scrollTop();
    };

    const nextResult = () => {
        setPage({
            ...page,
            currentPage: page.currentPage + 1,
            prevFirstItem: {
                ...page.prevFirstItem,
                [page.currentPage]: page.firstItem,
            },
            selector: {
                [selectorType]: page.lastItem,
            },
        });
        scrollTop();
    };

    const getTotalPages = () => {
        if (totalCount < 10) {
            return 1;
        } else {
            return Math.ceil(totalCount / 10);
        }
    };

    const scrollTop = () => {
        if (window !== undefined && document !== undefined) {
            const container = document.querySelector('main');
            container.scrollTo({ behavior: 'smooth', top: 0 });
        }
    };

    useEffect(() => {
        return () => {
            reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subcategoryId]);

    return (
        <div className='paginaiton-bloc' style={style}>
            {getTotalPages() === 1 ? null : (
                <>
                    <button
                        className={clsx('border prev', {
                            disabled: page.currentPage <= 1,
                        })}
                        onClick={prevResult}
                        title='Page précedente'
                    >
                        <div className='icon icon-arrow-big-left' />
                    </button>
                    <div className='total title-subcat'>
                        {page.currentPage} / {getTotalPages()}
                    </div>
                    <button
                        className={clsx('border next', {
                            disabled: page.currentPage === getTotalPages(),
                        })}
                        onClick={nextResult}
                        title='Page suivante'
                    >
                        <div className='icon icon-arrow-big-right' />
                    </button>
                </>
            )}
        </div>
    );
}

Pagination.defaultProps = {
    desc: false,
    subcategoryId: null,
};

Pagination.propTypes = {
    desc: PropTypes.bool,
    style: PropTypes.object.isRequired,
    subcategoryId: PropTypes.number,
};

export default Pagination;
