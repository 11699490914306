import React, { useState, useEffect } from 'react';

import useKioskDb from '../../../../database/kiosk/useKioskDb';
import SEO from '../../../components/SEO';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';

import './AdminSettingsPage.styl';

function AdminSettingsPage() {
    const [settings, setSettings] = useState(null);

    const db = useKioskDb();

    useEffect(() => {
        db.allDocs({ include_docs: true }).then((allNodes) => {
            const { catalogue, setting } = allNodes.rows[0].doc;
            setSettings({ ...setting, catalogue: catalogue.name });
        });
    }, [db]);

    const getEmail = (email) => {
        if (typeof email === 'string') {
            return email;
        }
        if (Array.isArray(email)) {
            return email.join(', ');
        }
    };

    return (
        <BreadcrumbLayout>
            <SEO title='Medikiosk' />
            <h1 className='center'>Informations borne</h1>
            {settings !== null ? (
                <div className='settings-page'>
                    <div>
                        <div className='left'>Nom catalogue</div>
                        <div className='right'>{settings.catalogue}</div>
                    </div>
                    <div>
                        <div className='left'>Nom pharmacie</div>
                        <div className='right'>{settings.name}</div>
                    </div>
                    <div>
                        <div className='left'>Adresse</div>
                        <div className='right'>{settings.address}</div>
                    </div>
                    <div>
                        <div className='left'>Code postal</div>
                        <div className='right'>{settings.zip_code}</div>
                    </div>
                    <div>
                        <div className='left'>Ville</div>
                        <div className='right'>{settings.city}</div>
                    </div>
                    <div>
                        <div className='left'>Téléphone</div>
                        <div className='right'>{settings.phone}</div>
                    </div>
                    <div>
                        <div className='left'>Email de l'utilisateur</div>
                        <div className='right'>{getEmail(settings.email)}</div>
                    </div>

                    <div>
                        <div className='left'>Déclenchement écran de veille</div>
                        <div className='right'>{settings.screen_saver_trigger / 60000} min</div>
                    </div>
                    <div>
                        <div className='left'>Logo</div>
                        <div className='right'>
                            <div className='settings-pharmacy-logo'>
                                {settings.logo !== null && <img src={settings.logo} alt='logo pharmacie' />}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </BreadcrumbLayout>
    );
}

AdminSettingsPage.propTypes = {};

export default AdminSettingsPage;
