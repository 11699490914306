import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ClampLines from 'react-clamp-lines';
import Slider from 'react-slick';

import './ProductItemSimilarProducts.styl';
import { useProductDb } from '../../../database/products/useProductDb';
import useIsMounted from '../../../hooks/useIsMounted';
import ProductImage from '../ProductImage/index';
import ProductLink from '../ProductLkink';
import { useSidebar } from '../Sidebar/SidebarContext';

function NextButton(props) {
    const { onClick } = props;
    return (
        <button className=' button-next' onClick={onClick}>
            <div className='icon icon-arrow-big-right' />
            <div className='text'>Voir d’autres articles</div>
        </button>
    );
}

function ProductItemSimilarProducts({ product }) {
    const sliderRef = useRef(null);
    const isMounted = useIsMounted();
    const { isOpen } = useSidebar();

    useEffect(() => {
        requestAnimationFrame(() => {
            window.dispatchEvent(new Event('resize'));
        });
    }, [isOpen]);

    const productDb = useProductDb();
    const productsIds = product.cross_selling.map((p) => p?._id ?? p?.id);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (products.length !== 0 && products.length === productsIds.length) {
            return;
        }

        productDb
            .allDocs({ include_docs: true, keys: productsIds })
            .then((resp) => {
                if (isMounted()) {
                    setProducts(resp.rows.map((row) => row.doc));
                }
            })
            .catch((err) => {
                console.error('ProductItemSimilarProducts -> err', err);
            });
    }, [productsIds, products, productDb, isMounted]);

    const manageSlide = useCallback(
        (slides) => {
            if (slides <= 1) {
                return slides;
            }
            return isOpen ? Number(slides) - 1 : slides;
        },
        [isOpen],
    );

    const manageInitialSlides = () => {
        return productsIds.length < 4 ? productsIds.length : manageSlide(4);
    };

    const reactSlickSliderSettings = {
        adaptiveHeight: false,
        arrows: true,
        autoplay: false,
        dots: false,
        infinite: true,
        nextArrow: <NextButton onClick={() => sliderRef.current.slickNext()} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToScroll: manageSlide(3),
                    slidesToShow: manageSlide(3),
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToScroll: manageSlide(2),
                    slidesToShow: manageSlide(2),
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                },
            },
        ],
        slidesToScroll: manageInitialSlides(),
        slidesToShow: manageInitialSlides(),

        speed: 500,
    };

    return (
        <div className='similar-products-bloc'>
            <div className='h3-like'>D’autres articles peuvent vous intéresser</div>
            <div className='thumbnails-wrapper'>
                <Slider ref={sliderRef} {...reactSlickSliderSettings}>
                    {products.map((item) => (
                        <ProductLink key={`cross-selling-id-${item.id}`} id={item._id} className='mini-card'>
                            <div className='img-container'>
                                <ProductImage {...item} />
                            </div>
                            <ClampLines
                                className='title product-label'
                                id={`product-similar-clamp-${item._id}`}
                                text={item.name}
                                buttons={false}
                                lines={1}
                            />
                        </ProductLink>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

ProductItemSimilarProducts.propTypes = {
    product: PropTypes.object.isRequired,
};

NextButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ProductItemSimilarProducts;
