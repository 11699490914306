// import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import './SubCategoryList.styl';
import useKioskDb from '../../../../database/kiosk/useKioskDb';
import { getSubCategoryPath } from '../../../../utils/products';
import CardSubCategory from '../../../components/CardSubCategory';
import SEO from '../../../components/SEO';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';

// eslint-disable-next-line react/prop-types
function SubCategoryList({ categoryId }) {
    const [category, setCategory] = useState(null);
    const [time, setTime] = useState('0s');
    const db = useKioskDb();

    useEffect(() => {
        const timer = setTimeout(() => {
            setTime('.5s');
        }, 1000);

        db.allDocs({ include_docs: true }).then((allNodes) => {
            const categories = allNodes.rows[0].doc.catalogue.categories;
            const cat = categories.filter(
                // eslint-disable-next-line eqeqeq
                (item) => item.id == categoryId,
            )[0];
            setCategory(cat);
        });

        return () => clearTimeout(timer);
    }, [categoryId, db]);

    if (category === null) {
        return <div>Chargement</div>;
    }

    const Headings = (
        <>
            <h1>{category.name}</h1>
            <div className='cat-description blue'>{category.description}</div>
        </>
    );

    return (
        <BreadcrumbLayout crumbs={{ category }} headings={Headings}>
            <SEO title='Medikiosk' />
            <div id='page-subcat'>
                <div className='subcategories-list' style={{ animationDuration: time }}>
                    {category.sub_categories.map((subcat) => (
                        <CardSubCategory
                            key={`home-subcat-${subcat.id}`}
                            {...subcat}
                            path={getSubCategoryPath(category.id, subcat.id)}
                        />
                    ))}
                </div>
            </div>
        </BreadcrumbLayout>
    );
}

// SubCategoryList.propTypes = {
//     // location: PropTypes.object.isRequired,
//     categoryId: PropTypes.string.isRequired,
// };

export default SubCategoryList;
