import { useMatch } from '@reach/router';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { PAGE_DIAGNOSTIC_RESULTS, PAGE_DIAGNOSTIC_RESULT_DETAILS, PAGE_CATEGORIES } from '../../../constants/router';

import './layout.styl';

function DiagnosticLayout({ children, className }) {
    const matchDiagnosticResultDetails = useMatch(PAGE_DIAGNOSTIC_RESULT_DETAILS);
    const matchDiagnosticResult = useMatch(PAGE_DIAGNOSTIC_RESULTS);

    return (
        <main>
            <div id='diagnostic-layout' className={className}>
                {matchDiagnosticResult === null && matchDiagnosticResultDetails === null && (
                    <div id='content-headings'>
                        <div className='heading'>
                            <h1 className='center'>
                                <span className='alt'>Mon diagnostic</span>
                                <br />
                                <span className='bold'> d'autonomie</span>
                            </h1>
                        </div>
                    </div>
                )}

                <div className='buttons-wrapper'>
                    {matchDiagnosticResultDetails !== null && (
                        <Link to={PAGE_DIAGNOSTIC_RESULTS} className='button'>
                            <div className='icon icon-arrow-small-left' />
                            <div>Retour</div>
                        </Link>
                    )}

                    <Link to={PAGE_CATEGORIES} className='button' style={{ marginLeft: 'auto' }}>
                        <div>Quitter</div>
                        <div className='icon icon-close' />
                    </Link>
                </div>

                <div id='content'>{children}</div>
            </div>
        </main>
    );
}

DiagnosticLayout.defaultProps = { className: '' };

DiagnosticLayout.propTypes = { children: PropTypes.node.isRequired, className: PropTypes.string };

export default DiagnosticLayout;
