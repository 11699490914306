import React from 'react';

import SEO from '../../../components/SEO';
import DiagnosticGlobalResult from '../../../features/diagnostic/components/DiagnosticResults/DiagnosticGlobalResult';
import DiagnosticListCategories from '../../../features/diagnostic/components/DiagnosticResults/DiagnosticListCategories';
import DiagnosticLayout from '../../../features/diagnostic/layout';

import './DiagnosticResultsPage.styl';

function DiagnosticResultsPage() {
    return (
        <DiagnosticLayout className='not-fixed'>
            <SEO title='Medikiosk' />
            <div id='diagnostic-results-page'>
                <DiagnosticGlobalResult />
                <DiagnosticListCategories />
            </div>
        </DiagnosticLayout>
    );
}

DiagnosticResultsPage.propTypes = {};

export default DiagnosticResultsPage;
