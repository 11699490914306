import { Link } from 'gatsby';
import React from 'react';

import { PAGE_HOME } from '../constants/router';
import logo from '../images/logo/icotype_fondfonce-no-bg@1x.png';
import FormLogin from '../views/components/FormLogin';

function IndexPage(props) {
    return (
        <div className='app-content'>
            <header className='home'>
                <div className='content'>
                    <div className='logo-bloc'>
                        <Link to={PAGE_HOME}>
                            <img src={logo} alt='' />
                            <div className='title-cat white'>Medikiosk</div>
                        </Link>
                    </div>
                </div>
            </header>
            <main>
                <div id='content'>
                    <h1 className='center' style={{ margin: '50px 0 24px' }}>
                        <span className='alt'>Bienvenue sur</span> Medikiosk
                    </h1>
                    <h2 className='center' style={{ marginBottom: '70px' }}>
                        Connectez-vous pour initialiser la borne
                    </h2>
                    <div style={{ margin: '0 auto', maxWidth: '400px' }}>
                        <div
                            className='form-container'
                            style={{
                                background: 'white',
                                borderRadius: '15px',
                                padding: '50px 30px ',
                            }}
                        >
                            <FormLogin />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default IndexPage;
