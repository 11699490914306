import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import { connect } from 'react-redux';

import './CardSubCategory.styl';
import { useImageSrc } from '../../../hooks/useImagesSrc';
import TogglSwitchButton from '../../features/productState/TogglSwitchButton/index';
import { getProductActiveState } from '../../features/productState/utils';

function CardSubCategory({ isActive, isAdmin, ...subcategory }) {
    const src = useImageSrc(subcategory);

    return !isActive && !isAdmin ? null : (
        <div className='subcategory-card'>
            <TogglSwitchButton type='subcategory' id={subcategory.id} data={subcategory} />
            <Link to={subcategory.path}>
                <div className='image-container'>
                    <img src={src} alt={`subcat-home-${subcategory.name}`} />
                </div>
                <div className='infos'>
                    <ClampLines
                        id={`subcategory-clamp-${subcategory.id}`}
                        text={subcategory.name}
                        className='title-subcat'
                        buttons={false}
                        lines={3}
                    />

                    <div className='rounded-icon'>
                        <div className='icon icon-arrow-big-right blue' />
                    </div>
                </div>
            </Link>
        </div>
    );
}

CardSubCategory.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

const mapState = (state, ownProps) => {
    const isActive = getProductActiveState('subcategory', ownProps, ownProps.id, state.productState);
    return {
        isActive,
        isAdmin: state.admin.loggedIn,
    };
};

export default connect(mapState, null)(CardSubCategory);
