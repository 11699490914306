import { Link } from 'gatsby';
import { Box, Table, TableBody, TableCell, TableRow } from 'grommet';
import React, { useState, useEffect } from 'react';

import { PAGE_ADMIN_CLIENTS } from '../../../../constants/router';
import useClientsDB from '../../../../database/clients/useClientsDb';
import { usePagination } from '../../../features/pagination/PaginationContext';
import Pagination from '../../../features/pagination/index';
import TableHeaderCustom from './TableHeader';

function List() {
    const [list, setList] = useState([]);

    const db = useClientsDB();
    const { page, setPage } = usePagination();

    useEffect(() => {
        let isCancelled = false;
        const baseSelector = {
            _id: page.selector,
        };

        db.find({
            limit: 10,
            selector: baseSelector,
            // sort: ['_id'],
            sort: [{ _id: 'desc' }],
        })
            .then((resp) => {
                if (!isCancelled) {
                    const results = resp.docs;
                    if (results.length > 0) {
                        setPage({
                            ...page,
                            firstItem: results[0]._id,
                            lastItem: results[results.length - 1]._id,
                        });
                    }
                    setList(results);
                }
            })
            .catch((err) => {
                if (!isCancelled) {
                    console.error('replicateDB -> err', err);
                }
            });
        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, page.selector]);

    if (list.length === 0) {
        return (
            <h3 className='center' style={{ marginTop: '30px' }}>
                Aucun client ne s'est enregistré pour le moment
            </h3>
        );
    }

    return (
        <div className='clients-list'>
            <Box basis='full' fill='horizontal'>
                <Table alignSelf='stretch'>
                    <TableHeaderCustom />
                    <TableBody>
                        {list.map((item) => (
                            <TableRow key={`client-list-item-${item._id}`}>
                                <TableCell scope='row'>
                                    <div className='firstname'>{item.client.firstname}</div>
                                </TableCell>
                                <TableCell scope='row'>
                                    <div className='lastname'>{item.client.lastname}</div>
                                </TableCell>
                                <TableCell scope='row'>
                                    <div className='email'>{item.client['email-0']}</div>
                                </TableCell>
                                <TableCell scope='row'>
                                    <div className='phone'>{item.client.phone}</div>
                                </TableCell>
                                <TableCell scope='row'>
                                    <div className='date'>{item.createdAt}</div>
                                </TableCell>
                                <TableCell scope='row'>
                                    <div className='date'>
                                        <Link to={`${PAGE_ADMIN_CLIENTS}/${item._id}`}>Voir le panier</Link>
                                    </div>
                                </TableCell>
                                <TableCell scope='row'>
                                    <div className='date'>{item._id}</div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination style={{ marginTop: '50px' }} desc />
            </Box>
        </div>
    );
}

export default List;
