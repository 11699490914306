import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import { connect } from 'react-redux';

import './CardCategory.styl';
import { useImageSrc } from '../../../hooks/useImagesSrc';
import { getCategoryPath } from '../../../utils/products';
import TogglSwitchButton from '../../features/productState/TogglSwitchButton';
import { getProductActiveState } from '../../features/productState/utils';

function CardCategory({ id, image, isActive, isAdmin, name }) {
    const src = useImageSrc({ image });

    return !isActive && !isAdmin ? null : (
        <div className='category-card'>
            <TogglSwitchButton type='category' id={id} />
            <Link to={getCategoryPath(id)}>
                <div className='img-container'>
                    <img src={src} alt={`cat-home-${name}`} />
                </div>
                <div className='infos'>
                    <ClampLines
                        id={`category-clamp-${id}`}
                        text={name}
                        className='title-cat'
                        buttons={false}
                        lines={3}
                    />

                    <div className='rounded-icon'>
                        <div className='icon icon-arrow-big-right blue' />
                    </div>
                </div>
            </Link>
        </div>
    );
}

CardCategory.propTypes = {
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
};

const mapState = (state, ownProps) => {
    const isActive = getProductActiveState('category', ownProps, ownProps.id, state.productState);
    return {
        isActive,
        isAdmin: state.admin.loggedIn,
    };
};

export default connect(mapState, null)(CardCategory);
