import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useProductDb } from '../../database/products/useProductDb';
import {
    brandFilterSelected,
    supplierFilterSelected,
} from '../components/ProductListFilterButtons/selectorFilterReducer';
import { usePagination } from '../features/pagination/PaginationContext';

const useProducts = ({ filters, subcategoryId }) => {
    const [products, setProducts] = useState([]);
    const [prevState, setPrevState] = useState({
        brandSelected: null,
        count: null,
        currentPage: null,
        filters: [],
    });
    const productDb = useProductDb();

    const [time, setTime] = useState('0s');
    const [parsedSubCategoryId, setParsedSubCategoryId] = useState(undefined);

    const { page, setPage, setTotalCount, totalCount } = usePagination();
    const brandSelected = useSelector(brandFilterSelected);
    const supplierSelected = useSelector(supplierFilterSelected);

    useEffect(() => {
        setParsedSubCategoryId(() => parseInt(subcategoryId, 10));
    }, [subcategoryId]);

    const getCommonSelector = useCallback((brand, filtersArray, subcategoryIdToParse, supplier) => {
        const selector = {
            'sub_category.id': parseInt(Number(subcategoryIdToParse), 10),
            tag_charge_max: { $exists: true },
            tag_loc: { $exists: true },
            tag_lpp: { $exists: true },
        };
        if (brand && brand !== '') {
            selector['brand'] = { $eq: brand };
        }

        if (supplier && supplier !== '') {
            selector['$or'] = [{ supplier: { $eq: supplier } }, { suppliers: { $elemMatch: { $eq: supplier } } }];
            // TODO change suppliers filter to simple one after all databases updated
            // selector['suppliers'] = { $elemMatch: { $eq: supplier } };
        }

        if (filtersArray?.length > 0) {
            filtersArray.forEach((filter) => {
                selector[filter] = { $eq: true };
            });
        }
        return selector;
    }, []);

    useEffect(() => {
        let isCancelled = false;
        const timer = setTimeout(() => {
            setTime('.5s');
        }, 1000);

        const commonSelector = getCommonSelector(brandSelected, filters, subcategoryId, supplierSelected);

        productDb
            .find({
                fields: ['_id'],
                selector: commonSelector,
            })
            .then((resp) => {
                if (!isCancelled) {
                    setTotalCount(resp.docs.length);
                }
            })
            .catch((err) => {
                if (!isCancelled) {
                    console.error('replicateDB -> err', err);
                }
            });

        if (
            parsedSubCategoryId &&
            (prevState.currentPage === null ||
                page?.currentPage !== prevState?.currentPage ||
                brandSelected !== prevState?.brandSelected ||
                supplierSelected !== prevState?.supplierSelected ||
                !isEqual(filters, prevState?.filters))
        ) {
            productDb
                .find({
                    limit: 10,
                    selector: { ...commonSelector, _id: page.selector },
                    sort: ['_id'],
                })
                .then((resp) => {
                    const results = resp.docs;
                    if (results.length > 0) {
                        setPage({
                            ...page,
                            firstItem: results[0]._id,
                            lastItem: results[results.length - 1]._id,
                        });
                    }
                    setProducts(results);
                })
                .catch((err) => {
                    if (!isCancelled) {
                        console.error('productDb find -> err', err);
                    }
                })
                .finally(() => {
                    setPrevState((current) => {
                        return {
                            ...current,
                            brandSelected,
                            currentPage: page.currentPage,
                            filters,
                            supplierSelected,
                        };
                    });
                });
        }

        return () => {
            isCancelled = true;
            clearTimeout(timer);
        };
    }, [
        page.selector,
        parsedSubCategoryId,
        productDb,
        page,
        setPage,
        getCommonSelector,
        brandSelected,
        filters,
        subcategoryId,
        supplierSelected,
        setTotalCount,
        prevState.currentPage,
        prevState?.brandSelected,
        prevState?.supplierSelected,
        prevState?.filters,
    ]);

    return { parsedSubCategoryId, products, time, totalCount };
};

export default useProducts;
