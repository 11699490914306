import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './FilterSelector.styl';

import FilterSelect from './FilterSelect';
import { setSupplierFilter, setBrandFilter } from './selectorFilterReducer';

const FilterSelector = ({ brands, suppliers }) => {
    const dispatch = useDispatch();
    const [brandValue, setBrandValue] = useState('');
    const [supplierValue, setSupplierValue] = useState('');

    useEffect(() => {
        dispatch(setBrandFilter(brandValue === '-' ? '' : brandValue));
    }, [dispatch, brandValue]);

    useEffect(() => {
        dispatch(setSupplierFilter(supplierValue === '-' ? '' : supplierValue));
    }, [dispatch, supplierValue]);

    return (
        <>
            <form className='filters-form'>
                {suppliers.length ? (
                    <FilterSelect
                        label='Fournisseurs'
                        options={suppliers ?? []}
                        filterValue={supplierValue}
                        setFilterValue={setSupplierValue}
                        btnClass='select-trigger-suppliers'
                    />
                ) : null}

                {brands.length ? (
                    <FilterSelect
                        label='Marques'
                        options={brands ?? []}
                        filterValue={brandValue}
                        setFilterValue={setBrandValue}
                        btnClass='select-trigger-brands'
                    />
                ) : null}
            </form>
        </>
    );
};

FilterSelector.propTypes = {
    brands: PropTypes.array.isRequired,
    suppliers: PropTypes.array.isRequired,
};

export default FilterSelector;
