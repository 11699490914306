import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import './CategoryList.styl';
import { PAGE_ADMIN_LOGIN } from '../../../../constants/router';
import useKioskDb from '../../../../database/kiosk/useKioskDb';
import SEO from '../../../components/SEO';
import { initState } from '../../../features/productState/reducer';
import { initCat } from '../../../features/productState/utils';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';
import List from './List';

const CategoryList = ({ initState, isInitailized }) => {
    const db = useKioskDb();

    useEffect(() => {
        if (!isInitailized) {
            db.allDocs({ include_docs: true }).then((allNodes) => {
                const categoriesState = initCat(allNodes.rows[0].doc.catalogue.categories);
                initState(categoriesState);
            });
        }
    }, [db, initState, isInitailized]);

    const Headings = (
        <h1 className='center'>
            <span className='alt'>Trouvez ici toutes les références</span>
            <br />
            de matériel médical
        </h1>
    );

    return (
        <BreadcrumbLayout headings={Headings}>
            <SEO title='Medikiosk' />
            {isInitailized ? <List /> : null}

            <Link to={PAGE_ADMIN_LOGIN} className='admin-button'>
                <div className='icon icon-settings blue' />
            </Link>
        </BreadcrumbLayout>
    );
};

CategoryList.propTypes = {
    initState: PropTypes.func.isRequired,
    isInitailized: PropTypes.bool.isRequired,
};

const mapsState = (state) => ({
    isInitailized: state.productState.initialized,
});

const mapDispatch = {
    initState,
};

export default connect(mapsState, mapDispatch)(CategoryList);
