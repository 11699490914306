import React from 'react';

import { diagnosticCategories } from '../../constants/categories';
import DiagnosticListItemCategories from './DiagnosticListItemCategories';
import './DiagnosticListCategories.styl';

function DiagnosticListCategories(props) {
    return (
        <div id='diagnostic-list-categories'>
            <h2>Nos préconisations</h2>
            <p>
                Cliquez sur chacune des catégories ci-dessous pour connaître les solutions adaptées à votre situation.
            </p>

            <div id='list-categories-wrapper'>
                {diagnosticCategories.map(({ id, imageCropped, label }) => (
                    <DiagnosticListItemCategories
                        key={`diagnostic-list-categories-item-${id}`}
                        label={label}
                        id={id}
                        image={imageCropped}
                    />
                ))}
            </div>
        </div>
    );
}

DiagnosticListCategories.propTypes = {};

export default DiagnosticListCategories;
