/* eslint-disable sort-keys-fix/sort-keys-fix */
import cn from 'clsx';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import './ProductItemPagination.styl';
import { BROWSING_BASE_PATH } from '../../../constants/router';
import { useProductDb } from '../../../database/products/useProductDb';

function ProductItemPagination({ product }) {
    const [pagesInfos, setPageInfos] = useState(null);
    const productDb = useProductDb();

    const basePath = `${BROWSING_BASE_PATH + product.sub_category.category.id}/${product.sub_category.id}/`;

    useEffect(() => {
        setPageInfos(null);

        const selector = {
            'sub_category.id': product.sub_category.id,
            tag_lpp: { $exists: true },
            tag_loc: { $exists: true },
            tag_charge_max: { $exists: true },
        };

        productDb
            .find({
                fields: ['_id'],
                selector,
            })
            .then((resp) => {
                const arrPoducts = resp.docs;
                const currentPage = arrPoducts.findIndex((item) => item._id === product._id);

                const nextPage =
                    currentPage === arrPoducts.length || arrPoducts[currentPage + 1] === undefined
                        ? null
                        : {
                              id: arrPoducts[currentPage + 1]._id,
                              index: currentPage + 1,
                          };

                const prevPage =
                    currentPage === 0 || arrPoducts[currentPage - 1] === undefined
                        ? null
                        : {
                              id: arrPoducts[currentPage - 1]._id,
                              index: currentPage - 1,
                          };

                setPageInfos({
                    currentPage,
                    nextPage,
                    prevPage,
                });
            })
            .catch((err) => {
                console.error('replicateDB -> err', err);
            });
    }, [product._id, product.sub_category.id, productDb]);

    function prev() {
        if (pagesInfos.prevPage === null) {
            return;
        }
        navigate(basePath + pagesInfos.prevPage.id);
    }

    function next() {
        if (pagesInfos.nextPage === null) {
            return;
        }
        navigate(basePath + pagesInfos.nextPage.id);
    }

    return (
        <div className='card-top'>
            <button
                className={cn('prev', 'h3-like', {
                    disabled: !pagesInfos?.prevPage,
                })}
                onClick={() => prev()}
            >
                <div className='icon icon-arrow-small-left white' />
                <span className='h3-like'>Produit précédent</span>
            </button>
            <button
                className={cn('next', 'h3-like', {
                    disabled: !pagesInfos?.nextPage,
                })}
                onClick={() => next()}
            >
                <span className='h3-like'>Produit suivant</span>
                <div className='icon icon-arrow-small-right white' />
            </button>
        </div>
    );
}

ProductItemPagination.propTypes = {
    product: PropTypes.object.isRequired,
};

export default ProductItemPagination;
