// import useAxios from 'axios-hooks';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import fields from '../../../constants/fields';
import { PAGE_ADMIN_SETTINGS } from '../../../constants/router';
import { logAdmin } from '../../../reducers/admin';
import { isLoggedInAdmin, handleLoginAdmin } from '../../../services/admin';
import FormError from '../FormError';
import InputBloc from '../InputBloc';

const FormLoginAdmin = ({ logAdmin }) => {
    const { login, password } = fields();
    const { errors, handleSubmit, register } = useForm();
    const [loginError, setLoginError] = useState(null);

    if (isLoggedInAdmin()) {
        navigate(PAGE_ADMIN_SETTINGS);
    }

    const onSubmit = (data) => {
        handleLoginAdmin(data)
            .then((response) => {
                logAdmin(response);
                navigate(PAGE_ADMIN_SETTINGS);
            })
            .catch((err) => {
                setLoginError(err);
            });
    };

    return (
        <>
            <form name='FormLoginAdmin' onSubmit={handleSubmit(onSubmit)}>
                {[login, password].map((item) => (
                    <InputBloc
                        key={`form-${item.name}-field-${item.name}`}
                        {...item}
                        register={register}
                        errors={errors}
                    />
                ))}

                <input type='submit' className='button-text' value='Se connecter' />

                <FormError formError={loginError} errorMessages={{ 401: 'Login ou mot de passe incorrect' }} />
            </form>
        </>
    );
};

FormLoginAdmin.propTypes = {
    logAdmin: PropTypes.func.isRequired,
};

const mapDispatch = { logAdmin };

export default connect(null, mapDispatch)(FormLoginAdmin);
