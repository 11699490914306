import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './DiagnosticListItemCategories.styl';
import { PAGE_DIAGNOSTIC_RESULTS } from '../../../../../constants/router';

function DiagnosticListItemCategories({ id, image, label }) {
    return (
        <Link to={`${PAGE_DIAGNOSTIC_RESULTS}/${id}`} className='diagnostic-list-item-category'>
            <div className='img-wrapper'>
                <img src={image} alt={label} />
            </div>
            <div className='label'>
                <div>{label}</div>
                <div className='icon-wrapper'>
                    <div className='icon icon-arrow-big-right' />
                </div>
            </div>
        </Link>
    );
}

DiagnosticListItemCategories.propTypes = {
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default DiagnosticListItemCategories;
