/* eslint-disable @typescript-eslint/no-unused-vars */
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PAGE_ADMIN_LOGIN } from '../../../constants/router';
import { isLoggedInAdmin } from '../../../services/admin';

const PrivateRouteAdmin = ({ component: Component, location, ...rest }) => {
    if (!isLoggedInAdmin() && location.pathname !== PAGE_ADMIN_LOGIN) {
        navigate(PAGE_ADMIN_LOGIN);
        return null;
    }
    return <Component {...rest} />;
};

PrivateRouteAdmin.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.object,
};

export default PrivateRouteAdmin;
