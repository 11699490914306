import { isArray } from 'lodash';
import { useEffect, useState } from 'react';

import { useProductDb } from '../../database/products/useProductDb';

const useBrands = ({ subcategoryId }) => {
    const [brands, setBrands] = useState([]);

    const productDb = useProductDb();

    useEffect(() => {
        let isCancelled = false;
        productDb
            .find({
                fields: ['brand'],
                selector: {
                    'sub_category.id': subcategoryId,
                },
            })
            .then((resp) => {
                if (!isCancelled) {
                    if (resp?.docs && isArray(resp?.docs)) {
                        const newBrands = [
                            ...new Set(
                                resp?.docs
                                    .sort((a, b) => {
                                        if (a.brand < b.brand) {
                                            return -1;
                                        }
                                        if (a.brand > b.brand) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .map((item) => item.brand)
                                    .filter((brand) => {
                                        return !!brand;
                                    }),
                            ),
                        ];
                        setBrands(newBrands);
                    }
                }
            })
            .catch((err) => {
                if (!isCancelled) {
                    console.error('replicateDB -> err', err);
                }
            });
        return () => {
            isCancelled = true;
        };
    }, [subcategoryId, productDb]);

    return { brands };
};

export default useBrands;
