/* eslint-disable jsx-a11y/alt-text */
import PropTypes from 'prop-types';
import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import Slider from 'react-slick';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import './ProductItemCarousel.styl';
import { useImagesSrc } from '../../../hooks/useImagesSrc';

function ProductItemCarousel({ images, initialSlide }) {
    const src = useImagesSrc({ images });

    // eslint-disable-next-line react/prop-types
    const ArrowNext = ({ onClick }) => (
        <button onClick={onClick} aria-label='suivant' className='slider-control next'>
            <i className='icon icon-arrow-big-right' />
        </button>
    );

    // eslint-disable-next-line react/prop-types
    const ArrowPrev = ({ onClick }) => (
        <button onClick={onClick} aria-label='précédent' className='slider-control prev'>
            <i className='icon icon-arrow-big-left' />
        </button>
    );

    const settings = {
        adaptiveHeight: true,
        arrows: true,
        autoplay: false,
        dots: false,
        infinite: true,
        nextArrow: <ArrowNext />,
        prevArrow: <ArrowPrev />,
        speed: 500,
    };

    return (
        <Slider {...settings} initialSlide={initialSlide} className='slider-products'>
            {src.map((item, i) => (
                <InnerImageZoom
                    key={`produit-img-${i}`}
                    className='image-zoom-container'
                    moveType='pan'
                    src={item}
                    fullscreenOnMobile
                />
            ))}
        </Slider>
    );
}

ProductItemCarousel.propTypes = {
    images: PropTypes.array.isRequired,
    initialSlide: PropTypes.number.isRequired,
};

export default ProductItemCarousel;
