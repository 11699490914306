// import PropTypes from 'prop-types'
import React from 'react';

import SEO from '../../../components/SEO';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';
import ClientListCount from './ClientListCount';
import List from './List';

import './ClientList.styl';

function ClientList(props) {
    return (
        // <MainLayout>
        <BreadcrumbLayout>
            <SEO title='Medikiosk' />
            <h1 className='center'>Clients</h1>
            <ClientListCount />
            <List />
        </BreadcrumbLayout>
        // </MainLayout>
    );
}

ClientList.propTypes = {};

export default ClientList;
