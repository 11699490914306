import propTypes from 'prop-types';
import React from 'react';

import './ProductListFilterButtons.styl';

import useBrands from '../../hooks/useBrands';
import useSuppliers from '../../hooks/useSuppliers';
import FilterButton from './FilterButton';
import FilterSelector from './FilterSelector';

function ProductListFilterButtons(props) {
    const { subcategoryId } = props;
    const { brands } = useBrands({ subcategoryId });
    const { suppliers } = useSuppliers({ subcategoryId });

    const filters = [
        {
            icon: 'ico-lpp',
            value: 'tag_lpp',
        },
        {
            icon: 'ico-loc',
            value: 'tag_loc',
        },
        {
            icon: 'icon icon-weight blue',
            value: 'tag_charge_max',
        },
    ];

    return (
        <div className='filters-bloc'>
            <div className='title'>Filtrer par :</div>
            {filters.map((item, i) => (
                <FilterButton key={`filter-button-${i}`} {...item} />
            ))}
            {suppliers ? <FilterSelector brands={brands ?? []} suppliers={suppliers ?? []} /> : null}
        </div>
    );
}

ProductListFilterButtons.propTypes = {
    subcategoryId: propTypes.number.isRequired,
};

export default ProductListFilterButtons;
