const apiURL = process.env.GATSBY_API_URL;

export const API_ROUTE_USER_LOGIN = {
    method: 'POST',
    url: apiURL + '/oauth/token',
};

export const API_ROUTE_GET_KIOSK = {
    method: 'POST',
    url: apiURL + '/kiosk',
};
