/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 10,
        color: '#2B5279',
        marginBottom: '25px',
    },
    mrb: {
        marginBottom: '5px',
    },
    textInline: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    marginRight: {
        marginRight: '3px',
    },
    bold: {
        fontWeight: 'bold',
    },
    semiBold: {
        fontWeight: 600,
        fontSize: 8,
        color: '#4B4D4C',
    },
    center: {
        color: '#173859',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '40px',
    },
    product: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '20px',
    },
    border: {
        height: '1px',
        width: '100%',
        backgroundColor: '#E7EEF4',
        marginBottom: '15px',
    },
    image: {
        height: 88,
        width: 88,
        objectFit: 'contain',
        marginRight: '27px',
    },
    title: {
        color: '#2B5279',
        fontSize: 13,
        fontWeight: 800,
    },
    description: {
        margin: '6px 0',
    },
    attr: {
        flexBasis: '50%',
        minWidth: '50%',
        maxWidth: '50%',
        flexDirection: 'row',
        marginBottom: '3px',
    },
    text8: {
        fontSize: 8,
        color: '#4B4D4C',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: '#4B4D4C',
    },
};
