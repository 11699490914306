import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import { connect } from 'react-redux';

import './CardProduct.styl';
import { getProductPath } from '../../../utils/products';
import { getProductActiveState } from '../../features/productState/utils';
import ProductImage from '../ProductImage/index';

function CardProduct({ isActive, isAdmin, product }) {
    const { _id, name, tag_charge_max, tag_loc, tag_lpp } = product;
    const path = getProductPath(product);

    return !isActive && !isAdmin ? null : (
        <div className='product-card'>
            <div className='card-top'>{tag_charge_max ? <div className='icon icon-weight dark-blue' /> : null}</div>
            <Link to={path} className='image-container'>
                <ProductImage {...product} />
            </Link>
            <div className='infos'>
                <div className='tags'>
                    {tag_lpp ? <div className='ico-lpp' /> : null}
                    {tag_loc ? <div className='ico-loc' /> : null}
                </div>
                <Link className='product-label' to={path}>
                    <ClampLines id={`product-clamp-${_id}`} text={name} buttons={false} lines={2} />
                </Link>
            </div>
        </div>
    );
}

CardProduct.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    product: PropTypes.object.isRequired,
};

const mapState = (state, ownProps) => {
    const isActive = getProductActiveState('product', ownProps.product, ownProps.product.id, state.productState);
    return {
        isActive,
        isAdmin: state.admin.loggedIn,
    };
};

export default connect(mapState, null)(CardProduct);
