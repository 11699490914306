import { PDFDownloadLink } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { PAGE_ADMIN_CLIENTS } from '../../../../constants/router';
import useClientsDB from '../../../../database/clients/useClientsDb';
import useKioskDb from '../../../../database/kiosk/useKioskDb';
import ProductImage from '../../../components/ProductImage/index';
import SEO from '../../../components/SEO';
import DocumentToPrint from '../../../features/print/DocumentToPrint/index';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';
import DocumentPDF from './DocumentPDF';

import './ClientListDetails.styl';

function ClientListDetails({ clientListId }) {
    const [data, setData] = useState(null);
    const componentRef = useRef();

    const clientDb = useClientsDB();
    const kioskDb = useKioskDb();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        clientDb.get(clientListId).then((details) => {
            kioskDb.allDocs({ include_docs: true }).then((kiosk) => {
                setData({
                    data: details,
                    pharmacyInfos: kiosk.rows[0].doc.setting,
                });
            });
        });
    }, [clientListId, clientDb, kioskDb]);

    return (
        <BreadcrumbLayout
            backLink={{
                label: 'Retour à la liste',
                path: PAGE_ADMIN_CLIENTS,
            }}
        >
            <SEO title='Medikiosk' />
            <h1 className='center'>Détails client</h1>
            <div className='client-list-details'>
                <div className='client'>
                    <h2>Client</h2>
                    <div className='details-bloc'>
                        <div className='detail-item'>
                            <div className='key'>Prénom</div>
                            <div className='value'>{data && data.data.client.firstname}</div>
                        </div>

                        <div className='detail-item'>
                            <div className='key'>Nom</div>
                            <div className='value'>{data && data.data.client.lastname}</div>
                        </div>

                        <div className='detail-item'>
                            <div className='key'>Email</div>
                            <div className='value'>{data && data.data.client.email}</div>
                        </div>

                        <div className='detail-item'>
                            <div className='key'>Téléphone</div>
                            <div className='value'>{data && data.data.client.phone}</div>
                        </div>

                        <div className='detail-item'>
                            <div className='key'>Date</div>
                            <div className='value'>{data && data.data.createdAt}</div>
                        </div>
                    </div>
                </div>
                <div className='list-bloc'>
                    <h2>Liste des produits</h2>
                    <div className='list'>
                        {data &&
                            data.data.list.map((item) => (
                                <div className='cart-list-item' key={`client-detail-product-${item.id}`}>
                                    <div className='img-container'>
                                        <ProductImage {...item.product} />
                                    </div>
                                    <div className='right'>
                                        <div className='name product-label'>{item.product.name}</div>
                                        <div className='attr'>
                                            <span className='bold'>Quantité :</span>
                                            {item.count}
                                        </div>
                                        <div className='attr'>
                                            <span className='bold'>Location :</span>
                                            {item.product.tag_loc ? 'Oui' : 'Non'}
                                        </div>
                                        <div className='attr'>
                                            <span className='bold'>Couleur :</span>
                                            {item.product.colors}
                                        </div>
                                        <div className='attr'>
                                            <span className='bold'>Remboursement :</span>
                                            {item.product.tag_lpp ? 'Oui' : 'Non'}
                                        </div>
                                        <div className='attr'>
                                            <span className='bold'>Prix :</span>
                                            {item.product.price !== 0 ? <>{item.product.price} € TTC</> : 'N/C'}
                                        </div>
                                        <div className='attr'>
                                            <span className='bold'>Poids :</span>
                                            {item.product.weight} kg
                                        </div>
                                        <div className='attr'>
                                            <span className='bold'>Marque :</span>
                                            {item.product.brand}
                                        </div>
                                        <div className='attr'>
                                            <span className='bold'>Charge max :</span>
                                            {item.product.maximum_charge} kg
                                        </div>
                                        <div className='description attr'>
                                            <span className='bold'>Description :</span>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: item.product.description,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div style={{ display: 'none' }}>
                        <DocumentToPrint data={data !== null ? data : {}} ref={componentRef} />
                    </div>

                    <div className='bloc-buttons'>
                        <button className='border' type='button'>
                            <div className='icon icon-pdf' />
                            {data !== null ? (
                                <PDFDownloadLink
                                    document={<DocumentPDF data={data} />}
                                    fileName='list-client.pdf'
                                    className='title-subcat'
                                >
                                    {({ loading }) => (loading ? 'Préparation en cours..' : 'Exporter au format PDF')}
                                </PDFDownloadLink>
                            ) : null}
                        </button>

                        <button className='border' type='button' onClick={handlePrint}>
                            <div className='icon icon-print' />
                            <div className='title-subcat'>Imprimer</div>
                        </button>
                    </div>
                </div>
            </div>
        </BreadcrumbLayout>
    );
}

ClientListDetails.propTypes = {
    clientListId: PropTypes.string.isRequired,
};

export default ClientListDetails;
