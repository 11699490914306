/* eslint-disable @typescript-eslint/no-unused-vars */
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { PAGE_HOME } from '../../../constants/router';
import { isLoggedIn } from '../../../services/auth';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    if (!isLoggedIn() && location.pathname !== PAGE_HOME) {
        navigate(PAGE_HOME);
        return null;
    }
    return <Component {...rest} />;
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    location: PropTypes.object,
};

export default PrivateRoute;
