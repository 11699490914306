import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import './DiagnosticResultDetails.styl';
import { diagnosticCategories } from '../../constants/categories';
import { getAutonomyByCategory } from '../../utils';

function DiagnosticResultDetails({ categoryId }) {
    const userResponses = useSelector((state) => state.diagnostic.userResponses);

    const intCategoryId = parseInt(categoryId, 10) - 1;

    const category = diagnosticCategories[intCategoryId];
    // const { chip, color, result, title } = getGlobalAutonomy(userResponses);

    const { chip, color, result, title } = getAutonomyByCategory(userResponses, categoryId);

    const textsResults = category.textResults[result];

    return (
        <div id='diagnostic-result-details-wrapper'>
            <div className='image-wrapper'>
                <img src={category.image} alt={category.label} />
                <h1 className='label'>{category.label}</h1>
            </div>
            <div className='content'>
                <div className='result-type'>
                    <h2>{title}</h2>
                    <div className={cn('result-chip', color)}>{chip}</div>
                </div>

                {textsResults === null ? (
                    <div className='subcategories-list'>
                        {category.subCategories.map((subCategory) => (
                            <div className='subcategory' key={subCategory.id}>
                                <h3>{subCategory.label}</h3>
                                <div className='subcategory-content'>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus deleniti
                                        corporis eum consequuntur, perferendis cumque, sed in dicta ab assumenda sunt.
                                        Cupiditate nulla, sunt iusto cumque quidem voluptatem dolorum placeat tempore
                                        soluta magnam, ab fuga numquam error aliquam sint doloribus?{' '}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='subcategories-list'>
                        {/* {textsResults.globalTexts !== undefined &&
                            textsResults.globalTexts.map((text, i) => <p key={`global-text-${i}`}>{text}</p>)} */}

                        {textsResults.sections.map(({ texts, title }) => (
                            <div className='subcategory' key={`conclusion-text-${title}`}>
                                <h3>{title}</h3>

                                <div className='subcategory-content'>
                                    {texts.map((text, i) => (
                                        <p key={`section-text-${title}-${i}`}>{text}</p>
                                    ))}
                                </div>
                            </div>
                        ))}

                        {textsResults.conclusion !== undefined &&
                            textsResults.conclusion.map((text, i) => <p key={`conclusion-text-${i}`}>{text}</p>)}
                    </div>
                )}
            </div>
        </div>
    );
}

DiagnosticResultDetails.propTypes = {
    categoryId: PropTypes.string.isRequired,
};

export default DiagnosticResultDetails;
