import { isArray } from 'lodash';
import { useEffect, useState } from 'react';

import { useProductDb } from '../../database/products/useProductDb';

const useSuppliers = ({ subcategoryId }) => {
    const [suppliers, setSuppliers] = useState([]);

    const productDb = useProductDb();

    useEffect(() => {
        let isCancelled = false;

        productDb
            .find({
                fields: ['supplier', 'suppliers'],
                selector: {
                    'sub_category.id': subcategoryId,
                },
            })
            .then((resp) => {
                if (!isCancelled) {
                    if (resp?.docs && isArray(resp?.docs)) {
                        const newSuppliers = new Set();
                        resp?.docs.forEach((item) => {
                            // TODO remove this if statement after all databases updated
                            if ('supplier' in item) {
                                newSuppliers.add(item.supplier);
                            } else if ('suppliers' in item) {
                                item.suppliers.forEach((s) => {
                                    newSuppliers.add(s);
                                });
                            }
                        });
                        setSuppliers(Array.from(newSuppliers).filter(Boolean).sort());
                    }
                }
            })
            .catch((err) => {
                if (!isCancelled) {
                    console.error('replicateDB -> err', err);
                }
            });
        return () => {
            isCancelled = true;
        };
    }, [subcategoryId, productDb]);

    return { suppliers };
};

export default useSuppliers;
