import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { addToCart, removeItemFromCart } from '../reducer';

import './AddToCartButton.styl';

function AddToCartButton({ addToCart, isAdmin, isSelected, isSmall, product, removeItemFromCart }) {
    const toggleAddToCart = () => {
        if (!isSelected) {
            addToCart(product);
        } else {
            removeItemFromCart(product._id);
        }
    };

    return isAdmin ? null : (
        <button
            className={cn(isSmall ? 'squarred-icon' : 'add-to-cart', {
                added: isSelected,
            })}
            onClick={() => toggleAddToCart()}
        >
            <div className={cn('icon', isSelected ? 'icon-listeon white' : 'icon-listeoff blue')} />
            {isSmall ? null : (
                <div
                    className={cn('title-subcat', {
                        white: isSelected,
                    })}
                >
                    {isSelected ? 'Article ajouté à ma liste' : 'Ajouter à ma liste'}
                </div>
            )}
        </button>
    );
}

AddToCartButton.defaultProps = {
    isSmall: false,
};

AddToCartButton.propTypes = {
    addToCart: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isSmall: PropTypes.bool,
    product: PropTypes.object.isRequired,
    removeItemFromCart: PropTypes.func.isRequired,
};

const mapDispatch = { addToCart, removeItemFromCart };

const mapState = (state, ownProps) => {
    const exist = state.cart.list.filter((item) => item.id === ownProps.product._id);
    return {
        isAdmin: state.admin.loggedIn,
        isSelected: exist.length > 0,
    };
};

export default connect(mapState, mapDispatch)(AddToCartButton);
