import React from 'react';
import { useSelector } from 'react-redux';

import SEO from '../../../components/SEO';
import DiagnosticStartStep from '../../../features/diagnostic/components/DiagnosticStartStep/DiagnosticStartStep';
import DiagnosticStep from '../../../features/diagnostic/components/DiagnosticStep/DiagnosticStep';
import DiagnosticLayout from '../../../features/diagnostic/layout';

function DiagnosticPage() {
    const isDirty = useSelector((state) => state.diagnostic.isDirty);

    return (
        <DiagnosticLayout>
            <SEO title='Medikiosk' />

            <div>{!isDirty ? <DiagnosticStartStep /> : <DiagnosticStep />}</div>
        </DiagnosticLayout>
    );
}

DiagnosticPage.propTypes = {};

export default DiagnosticPage;
