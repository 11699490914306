import axios from 'axios';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import { API_ROUTE_GET_KIOSK, API_ROUTE_USER_LOGIN } from '../../../constants/apiRoutes';
import fields from '../../../constants/fields';
import { PAGE_CATEGORIES } from '../../../constants/router';
import {
    initDatabaseCentralizedImages,
    initDatabaseClient,
    initDatabaseMain,
    initDatabaseProduct,
} from '../../../database/reducer';
import { handleLogin, isLoggedIn } from '../../../services/auth';
import FormError from '../FormError';
import InputBloc from '../InputBloc';

const oauthClientId = process.env.OAUTH_CLIENT_ID;
const oauthClientSecret = process.env.OAUTH_CLIENT_SECRET;

const FormLogin = ({ initDatabaseCentralizedImages, initDatabaseClient, initDatabaseMain, initDatabaseProduct }) => {
    const { email, password } = fields();
    const { errors, handleSubmit, register } = useForm();
    const [loginError, setLoginError] = useState(null);

    if (isLoggedIn()) {
        navigate(PAGE_CATEGORIES);
    }

    const onSubmit = (data) => {
        const credentials = {
            password: data.password,
            username: data.email,
        };

        const params = {
            client_id: oauthClientId,
            client_secret: oauthClientSecret,
            grant_type: 'password',
            ...credentials,
        };

        axios({
            data: params,
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'content-type': 'application/json',
            },
            method: API_ROUTE_USER_LOGIN.method,
            url: API_ROUTE_USER_LOGIN.url,
        })
            .then((resp) => {
                const { access_token } = resp.data;

                axios({
                    data: credentials,
                    headers: {
                        Authorization: 'Bearer ' + access_token,
                        'X-Requested-With': 'XMLHttpRequest',
                        'content-type': 'application/json',
                    },
                    method: API_ROUTE_GET_KIOSK.method,
                    url: API_ROUTE_GET_KIOSK.url,
                })
                    .then((resp) => {
                        const { kioskDatabaseName } = resp.data;

                        const user = {
                            ...credentials,
                            kioskDatabaseName,
                        };

                        handleLogin(user).then((resp) => {
                            const { clientDbName, mainDbName, productDbName } = resp;

                            navigate(PAGE_CATEGORIES);

                            initDatabaseMain({ name: mainDbName, ...credentials }).then(() => {
                                initDatabaseClient({ name: clientDbName, ...credentials }).then(() => {
                                    initDatabaseProduct({ name: productDbName, ...credentials }).then(() => {
                                        initDatabaseCentralizedImages({ ...credentials }).then(() => {});
                                    });
                                });
                            });
                        });
                    })
                    .catch((err) => {
                        console.error('onSubmit -> err', err);
                    });
            })
            .catch((err) => {
                console.error('err', err.response);
                setLoginError(err);
            });
    };

    return (
        <>
            <form name='FormLogin' onSubmit={handleSubmit(onSubmit)}>
                {/* {[email, password].map((item) => ( */}
                {[email, password].map((item) => (
                    <InputBloc
                        key={`form-${item.name}-field-${item.name}`}
                        {...item}
                        register={register}
                        errors={errors}
                    />
                ))}

                <input type='submit' className='button-text' value='Se connecter' />

                <FormError formError={loginError} errorMessages={{ 400: 'Email ou mot de passe incorrect' }} />
            </form>
        </>
    );
};

FormLogin.propTypes = {
    initDatabaseCentralizedImages: PropTypes.func.isRequired,
    initDatabaseClient: PropTypes.func.isRequired,
    initDatabaseMain: PropTypes.func.isRequired,
    initDatabaseProduct: PropTypes.func.isRequired,
};

const mapDispatch = {
    initDatabaseCentralizedImages,
    initDatabaseClient,
    initDatabaseMain,
    initDatabaseProduct,
};

export default connect(null, mapDispatch)(FormLogin);
