// import PropTypes from 'prop-types';
import React from 'react';

import SEO from '../../../components/SEO';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';
import SaveClient from './SaveClient';
import Sync from './Sync';
// import MainLayout from '../../../layouts/MainLayout';

import './SynchroPage.styl';

function SynchroPage(props) {
    return (
        // <MainLayout>
        <BreadcrumbLayout>
            <SEO title='Medikiosk' />
            <h1 className='center'>Synchronisation</h1>
            <div className='synchro-page'>
                <Sync />
                <SaveClient />
            </div>
        </BreadcrumbLayout>
        // </MainLayout>
    );
}

SynchroPage.propTypes = {};

export default SynchroPage;
