/* eslint-disable sort-keys-fix/sort-keys-fix */
import PropTypes from 'prop-types';
import React from 'react';

import CardProduct from '../../../components/CardProduct';
import Pagination from '../../../features/pagination';

function List({ products, subcategoryId, time }) {
    if (products === null) {
        return <div>Chargement</div>;
    }

    return products.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>Pas de produits dans cette catégorie</div>
    ) : (
        <>
            <div className='product-list' style={{ animationDuration: time }}>
                {products.map((product) => (
                    <CardProduct key={`home-product-${product._id}`} product={product} />
                ))}
            </div>
            <Pagination subcategoryId={Number(subcategoryId)} style={{ marginTop: products.length < 6 ? '50px' : 0 }} />
        </>
    );
}

List.propTypes = {
    products: PropTypes.array.isRequired,
    subcategoryId: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
};

export default List;
