import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { toggleFilter } from './reducer';

function FilterButton({ icon, isActive, label, toggleFilter, value }) {
    function toggleActive() {
        toggleFilter(value);
    }

    return (
        <button className={cn('filter-button', { active: isActive })} onClick={() => toggleActive()}>
            <div className={icon} />
            {label && <div className='breadcrumb-label'>{label}</div>}
            <div className='icon icon-plus blue' />
        </button>
    );
}

FilterButton.defaultProps = {
    label: null,
};

FilterButton.propTypes = {
    icon: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    label: PropTypes.string,
    toggleFilter: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

const mapState = (state, ownProps) => ({
    isActive: state.filters.find((filter) => filter === ownProps.value) !== undefined,
});

const mapDispatch = { toggleFilter };

export default connect(mapState, mapDispatch)(FilterButton);
