import { Button, Select } from 'grommet';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './FilterSelect.styl';

const FilterSelect = ({ btnClass, filterValue, label, options, setFilterValue }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className='select-wrapper'>
                <Select
                    clear
                    open={open}
                    options={['-', ...options]}
                    value={filterValue}
                    onChange={({ option }) => {
                        setFilterValue(option);
                        setOpen(false);
                    }}
                />
            </div>
            <Button
                className={`select-trigger ${btnClass} ${filterValue === '' || filterValue === '-' ? '' : 'active'}`}
                onClick={() => setOpen(!open)}
            >
                <div className='label'>
                    <span className='label-text'> {label} </span>
                    <span className='label-icon icon-arrow-small-right' />
                </div>
            </Button>
        </>
    );
};

FilterSelect.propTypes = {
    btnClass: PropTypes.string.isRequired,
    filterValue: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    setFilterValue: PropTypes.func.isRequired,
};

export default FilterSelect;
