/* eslint-disable eqeqeq */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import useKioskDb from '../../../../database/kiosk/useKioskDb';
import { getCategoryPath, getSubCategoryPath } from '../../../../utils/products';
import ProductListCount from '../../../components/ProductListCount/index';
import ProductListFilterButtons from '../../../components/ProductListFilterButtons';
import { resetFilters } from '../../../components/ProductListFilterButtons/reducer';
import { resetSelectorFilters } from '../../../components/ProductListFilterButtons/selectorFilterReducer';
import SEO from '../../../components/SEO';
import useProducts from '../../../hooks/useProducts';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';
import List from './List';

import './ProductList.styl';

function ProductList({ categoryId, filters, filtersCount, resetFilters, resetSelectorFilters, subcategoryId }) {
    const [catInfos, setCatInfos] = useState(null);

    const { parsedSubCategoryId, products, time, totalCount } = useProducts({
        filters,
        filtersCount,
        subcategoryId,
    });

    const db = useKioskDb();

    useEffect(() => {
        db.allDocs({ include_docs: true }).then((allNodes) => {
            const categories = allNodes.rows[0].doc.catalogue.categories;
            const category = categories.filter((item) => Number(item.id) === Number(categoryId))[0];
            category.path = getCategoryPath(category.id);
            const subcategory = category.sub_categories.filter((item) => Number(item.id) === Number(subcategoryId))[0];
            subcategory.path = getSubCategoryPath(category.id, subcategory.id);
            setCatInfos({ category, subcategory });
        });

        return () => {
            resetFilters();
            resetSelectorFilters();
        };
    }, [categoryId, db, resetFilters, resetSelectorFilters, subcategoryId]);

    if (catInfos === null) {
        return <div>Chargement</div>;
    }

    const Headings = <h1>{catInfos.subcategory.name}</h1>;

    return (
        <BreadcrumbLayout
            crumbs={{
                category: catInfos.category,
                subcategory: catInfos.subcategory,
            }}
            headings={Headings}
        >
            <SEO title='Medikiosk' />
            <div id='product-list-page'>
                <div className='filter-container'>
                    {parsedSubCategoryId ? (
                        <>
                            <ProductListCount
                                products={products}
                                totalCount={totalCount}
                                subcategoryId={parsedSubCategoryId}
                            />
                            <ProductListFilterButtons subcategoryId={parsedSubCategoryId} />
                        </>
                    ) : null}
                </div>

                <List products={products} time={time} subcategoryId={Number(subcategoryId)} />
            </div>
        </BreadcrumbLayout>
    );
}

ProductList.propTypes = {
    categoryId: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    filtersCount: PropTypes.number.isRequired,
    resetFilters: PropTypes.func.isRequired,
    resetSelectorFilters: PropTypes.func.isRequired,
    subcategoryId: PropTypes.string.isRequired,
};

const mapDisaptch = { resetFilters, resetSelectorFilters };

const mapState = (state) => ({
    filters: state.filters,
    filtersCount: state.filters.length,
});

export default connect(mapState, mapDisaptch)(ProductList);
