import React, { useEffect, useState } from 'react';

import useKioskDb from '../../../../database/kiosk/useKioskDb';
import CardCategory from '../../../components/CardCategory';

function List() {
    const [categories, setCategories] = useState([]);
    const [time, setTime] = useState('0s');
    const db = useKioskDb();

    useEffect(() => {
        const timer = setTimeout(() => {
            setTime('.5s');
        }, 1000);

        db.allDocs({ include_docs: true }).then((allNodes) => {
            setCategories(allNodes.rows[0].doc.catalogue.categories);
        });

        return () => clearTimeout(timer);
    }, [db]);

    return (
        <div className='categories-list' style={{ animationDuration: time }}>
            {categories.map((cat) => (
                <CardCategory key={`home-cat-${cat.id}`} {...cat} />
            ))}
        </div>
    );
}

export default List;
