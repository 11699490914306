import { TableCell, TableHeader, TableRow } from 'grommet';
// import PropTypes from 'prop-types';
import React from 'react';

function TableHeaderCustom(props) {
    return (
        <TableHeader>
            <TableRow>
                <TableCell scope='col' border='bottom'>
                    <div className='tab-cell-header'>Nom</div>
                </TableCell>
                <TableCell scope='col' border='bottom'>
                    <div className='tab-cell-header'>Prénom</div>
                </TableCell>
                <TableCell scope='col' border='bottom'>
                    <div className='tab-cell-header'>Email</div>
                </TableCell>
                <TableCell scope='col' border='bottom'>
                    <div className='tab-cell-header'>Téléphone</div>
                </TableCell>
                <TableCell scope='col' border='bottom'>
                    <div className='tab-cell-header'>Date</div>
                </TableCell>
                <TableCell scope='col' border='bottom'>
                    <div className='tab-cell-header'>Détails</div>
                </TableCell>
            </TableRow>
        </TableHeader>
    );
}

TableHeaderCustom.propTypes = {};

export default TableHeaderCustom;
