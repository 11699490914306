import React from 'react';

import { PAGE_CATEGORIES } from '../../../../constants/router';
import FormLoginAdmin from '../../../components/FormLoginAdmin';
import SEO from '../../../components/SEO';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';

const AdminLogin = () => {
    return (
        <BreadcrumbLayout
            backLink={{
                label: 'Retour au catalogue',
                path: PAGE_CATEGORIES,
            }}
        >
            <SEO title='Medikiosk' />
            <div style={{ marginBottom: '70px' }}>
                <h1 className='center'>
                    <span className='alt'>Espace </span>
                    Administrateur
                </h1>
            </div>
            <div style={{ margin: '0 auto', maxWidth: '400px' }}>
                <div
                    className='form-container'
                    style={{
                        background: 'white',
                        borderRadius: '15px',
                        padding: '50px 30px ',
                    }}
                >
                    <FormLoginAdmin />
                </div>
            </div>
        </BreadcrumbLayout>
    );
};

export default AdminLogin;
