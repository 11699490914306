/* eslint-disable sort-keys-fix/sort-keys-fix */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { getProductPath } from '../../../../utils/products';
import CardProduct from '../../../components/CardProduct';
import Pagination from '../../../features/pagination';
import { usePagination } from '../../../features/pagination/PaginationContext';
import useProductSearchQueries from '../../../pouchDBQueries/useProductSearchQueries';

function List({ searchTerm }) {
    const [products, setProducts] = useState(null);
    const [time, setTime] = useState('0s');
    const { page, setPage } = usePagination();

    const { loading, queryError, results } = useProductSearchQueries({
        params: { sort: ['_id'], page, limit: 10 },
        searchTerm,
    });

    useEffect(() => {
        let isCancelled = false;
        const timer = setTimeout(() => {
            setTime('.5s');
        }, 1000);

        if (!loading && !queryError) {
            if (!isCancelled) {
                if (results.length > 0) {
                    setPage((current) => {
                        return {
                            ...current,
                            firstItem: results[0]._id,
                            lastItem: results[results.length - 1]._id,
                        };
                    });
                }
                setProducts(results);
            }
        }
        return () => {
            isCancelled = true;
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, queryError, results, searchTerm]);

    if (products === null) {
        return <div>Chargement</div>;
    }

    return products.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>Pas de produits dans cette catégorie</div>
    ) : (
        <>
            <div className='product-list search-result' style={{ animationDuration: time }}>
                {products.map((product) => (
                    <CardProduct key={`home-product-${product._id}`} product={product} path={getProductPath(product)} />
                ))}
            </div>
            <Pagination style={{ marginTop: products.length < 6 ? '50px' : 0 }} />
        </>
    );
}

List.propTypes = {
    searchTerm: PropTypes.string.isRequired,
};

export default List;
