import PropTypes from 'prop-types';
import React from 'react';

import './ProductItemInfos.styl';

function ProductItemInfos({
    brand,
    code_ean,
    colors,
    dimension,
    lpp_code,
    maximum_charge,
    reference,
    supplier,
    suppliers,
    tag_loc,
    tag_lpp,
    weight,
}) {
    // TODO remove single supplier after all databases updated
    const suppliersList = supplier ? [supplier] : suppliers;

    return (
        <div className='infos-bloc'>
            <div className='h3-like'>Plus d'infos</div>
            <div className='infos-content'>
                <div className='infos-left'>
                    {dimension && (
                        <div className='txt-description'>
                            <span className='alt'>Dimension :</span> {dimension}
                        </div>
                    )}
                    {weight && (
                        <div className='txt-description'>
                            <span className='alt'>Poids :</span> {weight} kg
                        </div>
                    )}
                    {colors && (
                        <div className='txt-description'>
                            <span className='alt'>Couleur :</span> {colors}
                        </div>
                    )}
                    {brand && (
                        <div className='txt-description'>
                            <span className='alt'>Marque :</span> {brand}
                        </div>
                    )}
                    {maximum_charge && (
                        <div className='txt-description'>
                            <span className='alt'>Charge maximale :</span> {maximum_charge} kg
                        </div>
                    )}
                    {reference && (
                        <div className='txt-description'>
                            <span className='alt'>Référence :</span> {reference}
                        </div>
                    )}
                    {code_ean && (
                        <div className='txt-description'>
                            <span className='alt'>Code EAN :</span> {code_ean}
                        </div>
                    )}
                    {suppliersList.length > 0 && (
                        <div className='txt-description'>
                            <span className='alt'>Fournisseur(s) :</span> {suppliersList.join(', ')}
                        </div>
                    )}
                    {lpp_code && (
                        <div className='txt-description'>
                            <span className='alt'>Code LPP :</span> {lpp_code}
                        </div>
                    )}
                </div>
                <div className='infos-right'>
                    {tag_lpp && (
                        <div className='legend'>
                            <div className='ico ico-lpp' />
                        </div>
                    )}
                    {tag_loc && (
                        <div className='legend'>
                            <div className='ico ico-loc' />
                            <div className='h3-like'>Location</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

ProductItemInfos.defaultProps = {
    brand: null,
    code_ean: null,
    colors: null,
    dimension: null,
    lpp_code: null,
    maximum_charge: null,
    reference: null,
    // TODO remove single supplier after all databases updated
    supplier: null,
    suppliers: [],
    tag_loc: null,
    tag_lpp: null,
    weight: null,
};

ProductItemInfos.propTypes = {
    brand: PropTypes.string,
    code_ean: PropTypes.string,
    colors: PropTypes.string,
    dimension: PropTypes.string,
    lpp_code: PropTypes.string,
    maximum_charge: PropTypes.number,
    reference: PropTypes.string,
    // TODO remove single supplier after all databases updated
    supplier: PropTypes.string,
    suppliers: PropTypes.array,
    tag_loc: PropTypes.bool,
    tag_lpp: PropTypes.bool,
    weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ProductItemInfos;
