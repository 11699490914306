import React from 'react';
import { useDispatch } from 'react-redux';

import bgStartStep from '../../../../../images/diagnostic/bg-start-step.png';
import { startDiagnostic } from '../../reducer';
import './DiagnosticStartStep.styl';

function DiagnosticStartStep(props) {
    const dispatch = useDispatch();

    return (
        <div id='diagnostic-start-step-wrapper'>
            <div className='bg-img-wrapper'>
                <img src={bgStartStep} alt='background' />
            </div>
            <div className='content-wrapper'>
                <div className='icon-wrapper'>
                    <div className='icon icon2-clock' />
                </div>
                <p>
                    <span className='bold'>En seulement quelques minutes</span> <br />
                    déterminez votre degré de dépendance et obtenez les conseils adaptés <br />
                    de votre pharmacien
                </p>
                <button onClick={() => dispatch(startDiagnostic())}>Démarrer</button>
            </div>
        </div>
    );
}

export default DiagnosticStartStep;
