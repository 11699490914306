import PropTypes from 'prop-types';
import React from 'react';
import Pluralize from 'react-pluralize';

function ProductListCount({ products, totalCount }) {
    return (
        <div className='h3-like product-count'>
            {products !== null ? <Pluralize singular='produit' count={totalCount} zero='0 produit' /> : '...'}
        </div>
    );
}

ProductListCount.propTypes = {
    products: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
};

export default ProductListCount;
