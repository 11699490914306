import cn from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import './ProductItemImages.styl';

import { useImagesSrc } from '../../../hooks/useImagesSrc';
import Overlay from '../Overlay';
import ProductItemCarousel from '../ProductItemCarousel';

function ProductItemImages({ images }) {
    const src = useImagesSrc({ images });
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const imgRef = useRef();

    useEffect(() => {
        setSelectedIndex(0);
    }, [src]);

    function selectImage(index) {
        if (index === selectedIndex) {
            return;
        }
        setSelectedIndex(index);
    }

    const imgRatio = () => {
        const img = imgRef.current;
        if (img?.height && img?.width) {
            const ratio = img?.width / img?.height;
            return ratio > 1 ? 'landscape' : 'portrait';
        }
        return null;
    };

    return (
        <div className='image-container'>
            <div className='selected-image'>
                <img
                    ref={imgRef}
                    src={src[selectedIndex]}
                    alt=''
                    onClick={() => setIsOpen(true)}
                    onKeyPress={() => setIsOpen(true)}
                    role='presentation'
                    style={imgRatio() && imgRatio() === 'landscape' ? { height: 'auto', maxWidth: '100%' } : {}}
                />
            </div>
            <div className='thumbnails'>
                {src.map((item, index) => (
                    <div
                        key={`thumbnail-${index}`}
                        className={cn('thumbnail-container', {
                            selected: selectedIndex === index,
                        })}
                        onClick={() => selectImage(index)}
                        tabIndex={index}
                        role='presentation'
                    >
                        <img src={item} alt={`thumbnail-${index}`} />
                    </div>
                ))}
            </div>
            {isOpen && (
                <Overlay close={() => setIsOpen(false)}>
                    <ProductItemCarousel images={src} initialSlide={selectedIndex} />
                </Overlay>
            )}
        </div>
    );
}

ProductItemImages.propTypes = {
    images: PropTypes.array.isRequired,
};

export default ProductItemImages;
