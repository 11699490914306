import PropTypes from 'prop-types';
import React from 'react';

import SEO from '../../../components/SEO';
import DiagnosticProducts from '../../../features/diagnostic/components/DiagnosticResultDetails/DiagnosticProducts';
import DiagnosticResultDetails from '../../../features/diagnostic/components/DiagnosticResultDetails/DiagnosticResultDetails';
import DiagnosticLayout from '../../../features/diagnostic/layout';

function DiagnosticResultDetailsPage({ categoryId }) {
    return (
        <DiagnosticLayout>
            <SEO title='Medikiosk' />
            <div id='diagnostic-results-details-page'>
                <DiagnosticResultDetails categoryId={categoryId} />
                <DiagnosticProducts categoryId={categoryId} />
            </div>
        </DiagnosticLayout>
    );
}

DiagnosticResultDetailsPage.propTypes = {
    categoryId: PropTypes.string.isRequired,
};

export default DiagnosticResultDetailsPage;
