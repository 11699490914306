import React, { useEffect } from 'react';

import useClientsDB from '../../../../database/clients/useClientsDb';
import { usePagination } from '../../../features/pagination/PaginationContext';

function ClientListCount() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const [clients, setClients] = useState(null);
    const db = useClientsDB();
    const { setTotalCount } = usePagination();

    useEffect(() => {
        let isCancelled = false;

        const selector = {
            _id: { $exists: true },
        };

        db.find({
            fields: ['_id'],
            selector,
            sort: [{ _id: 'desc' }],
        })
            .then((resp) => {
                if (!isCancelled) {
                    setTotalCount(resp.docs.length);
                }
            })
            .catch((err) => {
                if (!isCancelled) {
                    console.error('replicateDB -> err', err);
                }
            });

        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db]);

    return <div className='h3-like clients-count'>{/* {clients !== null ? `${clients} listes` : '...'} */}</div>;
}

export default ClientListCount;
