import { Router } from '@reach/router';
import React from 'react';

import {
    PAGE_CATEGORIES,
    PAGE_CART,
    PAGE_SUBCATEGORIES,
    PRODUCT_LIST,
    PRODUCT_ITEM,
    PAGE_HOME,
    PAGE_ADMIN_SETTINGS,
    PAGE_ADMIN_LOGIN,
    PAGE_ADMIN_CLIENTS,
    PAGE_ADMIN_CLIENTS_DETAILS,
    PAGE_ADMIN_SYNCHRO,
    PAGE_SEARCH_RESULTS,
    PAGE_DIAGNOSTIC,
    PAGE_DIAGNOSTIC_RESULTS,
    PAGE_DIAGNOSTIC_RESULT_DETAILS,
} from '../../constants/router';
import PrivateRoute from '../../views/components/PrivateRoutes';
import PrivateRouteAdmin from '../../views/components/PrivateRoutesAdmin';
import MainLayout from '../../views/layouts/MainLayout';
import ClientList from '../../views/pages/admin/ClientList';
import ClientListDetails from '../../views/pages/admin/ClientListDetails/index';
import AdminLogin from '../../views/pages/admin/Login';
import AdminSettingsPage from '../../views/pages/admin/Settings';
import SynchroPage from '../../views/pages/admin/Synchro';
import CategoryList from '../../views/pages/app/CategoryList/index';
import DiagnosticPage from '../../views/pages/app/Diagnostic/DiagnosticPage';
import DiagnosticResultDetailsPage from '../../views/pages/app/Diagnostic/DiagnosticResultDetailsPage';
import DiagnosticResultsPage from '../../views/pages/app/Diagnostic/DiagnosticResultsPage';
import ProductItem from '../../views/pages/app/ProductItem';
import ProductList from '../../views/pages/app/ProductList/index';
import SearchResultList from '../../views/pages/app/SearchResultList/index';
import SubCategoryList from '../../views/pages/app/SubCategoryList/index';
import IndexPage from '../index';
import CartPage from './ma-liste';

const App = () => {
    return (
        <MainLayout>
            <Router>
                <PrivateRoute path={PAGE_CATEGORIES} component={CategoryList} />
                <PrivateRoute path={PAGE_SEARCH_RESULTS} component={SearchResultList} />
                <PrivateRoute path={PAGE_SUBCATEGORIES} component={SubCategoryList} />
                <PrivateRoute path={PRODUCT_LIST} component={ProductList} />
                <PrivateRoute path={PRODUCT_ITEM} component={ProductItem} />
                <PrivateRoute path={PAGE_CART} component={CartPage} />
                <PrivateRoute path={PAGE_DIAGNOSTIC} component={DiagnosticPage} />
                <PrivateRoute path={PAGE_DIAGNOSTIC_RESULTS} component={DiagnosticResultsPage} />
                <PrivateRoute path={PAGE_DIAGNOSTIC_RESULT_DETAILS} component={DiagnosticResultDetailsPage} />
                <PrivateRoute path={PAGE_ADMIN_LOGIN} component={AdminLogin} />
                <PrivateRouteAdmin path={PAGE_ADMIN_SETTINGS} component={AdminSettingsPage} />
                <PrivateRouteAdmin path={PAGE_ADMIN_CLIENTS} component={ClientList} />
                <PrivateRouteAdmin path={PAGE_ADMIN_CLIENTS_DETAILS} component={ClientListDetails} />
                <PrivateRouteAdmin path={PAGE_ADMIN_SYNCHRO} component={SynchroPage} />
                <IndexPage path={PAGE_HOME} />
            </Router>
        </MainLayout>
    );
};

export default App;
