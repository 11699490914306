import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './DiagnosticProducts.styl';
import { PAGE_CATEGORIES } from '../../../../../constants/router';
import { diagnosticCategories } from '../../constants/categories';

function DiagnosticProducts({ categoryId }) {
    const intCategoryId = parseInt(categoryId, 10) - 1;

    const category = diagnosticCategories[intCategoryId];

    const getImg = (img) => {
        const image = require(`../../../../../images/diagnostic/products/${img}`);
        return image;
    };
    return (
        <div id='diagnostic-products'>
            <h2>Produits préconisés</h2>

            <div className='products-list'>
                {category.products.map((product) => (
                    <div className='product' key={product.name}>
                        <div className='image-wrapper'>
                            <img src={getImg(product.img)} alt={product.name} />
                        </div>
                        <div className='label'>{product.name}</div>
                    </div>
                ))}
            </div>

            <div className='button-wrapper'>
                <Link to={PAGE_CATEGORIES}>Voir le catalogue complet</Link>
            </div>
        </div>
    );
}

DiagnosticProducts.propTypes = {
    categoryId: PropTypes.string.isRequired,
};

export default DiagnosticProducts;
