// import PropTypes from 'prop-types'
import React, { useState } from 'react';
import { Offline, Online } from 'react-detect-offline';

import { clientDbSave } from '../../../../database/init';
import { getCurrentUser } from '../../../../services/auth';

function SaveClient() {
    const [saved, setSaved] = useState(false);
    const { clientDbName, password, username } = getCurrentUser();

    function saveClientDb() {
        clientDbSave({ name: clientDbName, password, username }).then(() => {
            setSaved(true);
        });
    }

    return (
        <div className='client-save'>
            <h2>Sauvegarder la base de clients</h2>
            <Online>
                <button className='border' onClick={saveClientDb}>
                    <div className='icon icon-save' />
                    <div className='title-subcat'>Sauvegarder</div>
                </button>
            </Online>
            <Offline>
                <div className='warning'>
                    Vous êtes actuellement hors ligne, <br /> une connexion internet est nécessaire.
                </div>
            </Offline>
            <div className='saved'>{saved ? 'La base a bien été sauvegardée' : ''}</div>
        </div>
    );
}

SaveClient.propTypes = {};

export default SaveClient;
