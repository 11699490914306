import React from 'react';

import { PAGE_CATEGORIES } from '../../../../constants/router';
import SEO from '../../../components/SEO';
import SearchResultCount from '../../../features/search/SearchResultCount/index';
import BreadcrumbLayout from '../../../layouts/BreadcrumbLayout';
import List from './List';

// eslint-disable-next-line react/prop-types
function SearchResultList({ searchTerm }) {
    const Headings = <h1>Recherche : "{searchTerm}"</h1>;

    return (
        <BreadcrumbLayout
            backLink={{
                label: 'Retour au catalogue',
                path: PAGE_CATEGORIES,
            }}
            headings={Headings}
        >
            <SEO title='Medikiosk' />
            <div id='product-list-page'>
                <SearchResultCount searchTerm={searchTerm} />
                <List searchTerm={searchTerm} />
            </div>
        </BreadcrumbLayout>
    );
}

export default SearchResultList;
