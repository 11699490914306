import cn from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './DiagnosticGlobalResult.styl';
import { PAGE_CATEGORIES } from '../../../../../constants/router';
import { clearDiagnostic } from '../../reducer';
import { getGlobalAutonomy } from '../../utils';

function DiagnosticGlobalResult(props) {
    const dispatch = useDispatch();

    const userResponses = useSelector((state) => state.diagnostic.userResponses);

    const deleteAndClose = () => {
        dispatch(clearDiagnostic());
        navigate(PAGE_CATEGORIES);
    };

    const { chip, color, texts, title } = getGlobalAutonomy(userResponses);

    return (
        <div id='diagnostic-global-result-wrapper'>
            <div>
                <h1>Résultat</h1>

                <h2>{title}</h2>

                <div className={cn('result-chip', color)}>{chip}</div>
            </div>

            <div id='result-content'>
                {texts.map((text, i) =>
                    i === 0 ? (
                        <p className='bold' key={`result-content-${i}`}>
                            {text}
                        </p>
                    ) : (
                        <p key={`result-content-${i}`}>{text}</p>
                    ),
                )}
            </div>

            <div id='bottom-wrapper'>
                <button onClick={deleteAndClose}>Terminer</button>
            </div>
        </div>
    );
}

DiagnosticGlobalResult.propTypes = {};

export default DiagnosticGlobalResult;
